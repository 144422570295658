<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="back"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="评估标题" v-model="questionnaireName" disabled />
        <v-input
          label="昵称"
          v-model="searchParam.nickName"
          placeholder="请输入昵称"
        />
        <v-date-picker
          label="生成时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="生成开始时间"
          endPlaceholder="生成结束时间"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { psychologicalEvaluationScoreListUrl } from "./api.js";
import {} from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
export default {
  data() {
    return {
      searchParam: {
        nickName: null,
        startDate: null,
        endDate: null,
        id: null,
      },
      questionnaireName: null,
      tableUrl: psychologicalEvaluationScoreListUrl,
      headers: [
        {
          prop: "nickName",
          label: "昵称",
        },
        {
          prop: "score",
          label: "得分",
        },
        {
          prop: "createTime",
          label: "生成时间",
        },
      ],
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.startDate && !this.searchParam.endDate
          ? []
          : [this.searchParam.startDate, this.searchParam.endDate];
      },
      set(val) {
        [this.searchParam.startDate, this.searchParam.endDate] = val || [
          "",
          "",
        ];
      },
    },
  },
  created() {
    const { name, id } = this.$route.query;
    this.questionnaireName = name;
    this.searchParam.id = id;
  },
  mounted() {
    this.$setBreadList("问卷得分列表");
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>